@font-face {
  font-family: 'Corpo';
  src: url('./fonts/ABChanel-PB-SemiBold-L.woff2') format('woff2'),
    url('./fonts/ABChanel-PB-SemiBold-L.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

.h1 {
  font-size: clamp(2.75rem, -2.9643rem + 11.9048vw, 7.75rem);
  line-height: 1;
}

.h2 {
  font-size: clamp(2rem, -1.4286rem + 7.1429vw, 5rem);
  line-height: 1;
}

.h3 {
  font-size: clamp(2rem, -0.2857rem + 4.7619vw, 4rem);
  line-height: 1;
}

.h4 {
  font-size: clamp(1.5rem, 0.3571rem + 2.381vw, 2.5rem);
  line-height: 1.05;
}

.h5 {
  font-size: clamp(1rem, 0.8607rem + 0.5714vw, 1.375rem);
  line-height: 1.1;
}

.h6 {
  font-size: clamp(0.875rem, 0.7321rem + 0.2976vw, 1rem);
  line-height: 1.1;
}

.h6-sub {
  font-size: 1rem;
  line-height: 1.1;
}

.heading-7 {
  font-size: 14px;
  line-height: 1.1;
}

.subtitle {
  font-size: clamp(0.875rem, 0.8607rem + 0.5714vw, 1rem);
  line-height: 1.1;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.heading-7,
.subtitle,
.h6-sub {
  text-transform: uppercase;
  font-family: var(--primary-font);
  font-weight: 600;
}

.body-l {
  font-size: 1.125rem;
  line-height: 1.4;
  text-transform: none;
}

.body-s {
  font-size: 1rem;
  line-height: 1.4;
}

.body-m {
  font-size: 1rem;
  line-height: 1.4;
}

@media (max-width: 768px) {

  .h4,
  .h3,
  .h2,
  .h1 {
    line-height: 1.1;
  }

  .body-m {
    font-size: .875rem;
  }
}