.frame {
  position: relative;
}

.language-selector {
  position: absolute;
  right: var(--frame-with-padding);
  top: var(--frame-with-padding);
}

.language-selector ul {
  padding: 0;
  margin: 0 0 0;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all .2s ease-out;
  transform: translateY(20px);
}
.language-selector ul.active {
  opacity: 1;
  visibility: visible;
  height: auto;
  transform: translateY(0px);
}

.language-selector ul li a {
  display: block;
  margin-bottom: 1rem;
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
}

.btn.active-lang-menu,
.btn.active-lang {
  margin-bottom: 1rem;
  display: block;
  margin-left: auto;
  color: #7f7f7f ;
  transition: all .2s ease-in-out;
}
.active-lang:hover,
.active-lang.active {
  color: var(--black);
}
.active-lang:hover span,
.active-lang.active span {
  color: var(--black);
}



.lang-mb-name  {
  display: none;
}

@media (max-width: 768px) {
  .language-selector ul li a {
    margin-bottom: 1.5rem;
  }
  .active-lang {
    margin-bottom: 1.5rem;
  }
  .lang-mb-name  {
    display: block;
  }
  .lang-ds-name {
    display: none;
  } 

  .language-selector {
    right: 2.625rem;
    top: 32px;
  }
}