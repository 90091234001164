/* Begin custom styling */
@import url('./reset.css');
@import url('./typography.scss');
@import url('./_lang-selector.scss');

* {
  @media (min-width: 1023px) {
    cursor: none;
  }
}

:root {
  --primary-font: 'Corpo';
  --secondary-font: 'Helvetica', Arial, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;;

  --black: #000000;
  --grey: #6B6B6B;
  --light-grey: #E6E6E6;
  --white: #FFFFFF;

  // --frame-width: clamp(40px, 7vw, 56px);
  // --frame-width: clamp(0.875rem, -0.6571rem + 6.2857vw, 5rem);
  --frame-width: 4rem;
  --frame-with-padding: calc(var(--frame-width) + 40px);
  --bg-color: #fff;
  --line-color: #000;
  --viewport-min: 320px;
  --viewport-sm: 540px;

  --cursor-z-index: 99;
  --menu-z-index: 20;
  --where-z-index: 20;
  --line-x-z-index: 21;
  --line-y-z-index: 22;
  --where-closer-z-index: 23;
  --corner-element-z-index: 7;

  // --vh-100: calc(var(--vh, 1vh) * 100);
  --vh-100: 100vh;
}

// UTILS

.mobile-mt-3 {
  @media (max-width: 768px) {
    margin-top: 3rem !important;
  }
}

.desktop-mt-6 {
  @media (min-width: 768px) {
    margin-top: 6rem !important;

  }
}

.desktop {
  @media (max-width: 768px) {
    display: none;
  }
}

.mobile {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

#menu-closer {
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 24px;

  @media (max-width: 768px) {
    height: 35px;
  }
}

.menu-opener {
  color: var(--black);
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 24px;

  @media (max-width: 768px) {
    height: 35px;
  }
}

.line-top,
.line-bottom {
  height: var(--frame-width);
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  background-color: var(--black);

  z-index: var(--line-x-z-index);
}

.line-left,
.line-right {
  width: var(--frame-width);
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: var(--line-y-z-index);
}

.line-top {
  border-bottom: 1px solid var(--white);
  top: 0;
}

.line-bottom {
  border-top: 1px solid var(--white);
  bottom: 0;
}

.line-left {
  left: 0;
  border-right: 1px solid var(--white);
}

.line-right {
  right: 0;
  border-left: 1px solid var(--white);
}

.mb-mid {
  margin-bottom: 1.5rem;
}




.btn {
  background-color: transparent;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
}

.btn:focus {
  outline: none;
}

.btnMenu{
  display: block;
  margin-bottom: 1rem;
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
  transition: all .4s ease-in-out;
  margin-left:auto;
  padding-bottom:0 !important;

  &:hover {
    opacity: 1;
    color: var(--black);
  }
  

  @media (max-width: 1023px) {
    margin-bottom: .5rem;
  }
  
}




@media (max-width: 768px) {
  .mb-mid {
    margin-bottom: 1rem;
  }
}


::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

.invisible {
  visibility: hidden;
}

html {
  font-size: 16px;
}

body {
  background: #fff;
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;
  min-width: var(--viewport-min);
  overflow-x: hidden;
  font-family: var(--secondary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  display: inline-block;
  text-decoration: none;
  color: #000;
  position: relative;
  white-space: nowrap;
}

a:visited,
a:active {
  color: #000;
}

img {
  max-width: 100%;
}

#outer-frame {
  border: var(--frame-width) solid var(--bg-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: var(--vh-100);

  // max-height: -webkit-fill-available;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 5;
  /* The outer frame should clip all inner content, but not edge nav */
}

#frame-lines {
  border: var(--frame-width) solid #0000;
  position: absolute;
  top: calc(-1 * var(--frame-width));
  left: calc(-1 * var(--frame-width));
  width: 100%;
  height: 100%;
}

#frame-lines::before {
  content: "";
  position: absolute;
  width: calc(100% + var(--frame-width) * 2);
  left: calc(-1 * var(--frame-width));
  height: 100%;
  // max-height: -webkit-fill-available;
  top: 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

#frame-lines::after {
  content: "";
  position: absolute;
  height: calc(100% + var(--frame-width) * 2);
  top: calc(-1 * var(--frame-width));
  width: 100%;
  left: 0;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  pointer-events: none;
}

.corner-prevent-click {
  z-index: var(--corner-element-z-index);
  box-sizing: border-box;
  position: fixed;
  width: var(--frame-width);
  height: var(--frame-width);

  &.left-top {
    left: 0;
    top: 0;
  }
  &.left-bottom {
    left: 0;
    bottom: 0;
  }
  &.right-top {
    right: 0;
    top: 0;
  }
  &.right-bottom {
    right: 0;
    bottom: 0;
  }
}

.edge-nav {
  position: fixed;
  box-sizing: border-box;
  z-index: 6;

  &.active {
    a {
      opacity: 1 !important;
    }
    button{
      opacity: 1 !important;
    }
  }
}

.edge-nav a {
  opacity: 0;
  transition: opacity 0.3s;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1023px) {
    opacity: 1;
  }

  @media (max-width: 768px) {
    opacity: 0;
  }
}

.edge-nav a:hover {
  opacity: 1;
}

.edge-nav button {
  opacity: 0;
  transition: opacity 0.3s;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1023px) {
    opacity: 1;
  }

  @media (max-width: 768px) {
    opacity: 0;
  }
}

.edge-nav button:hover {
  opacity: 1;
}


#top-nav,
#bottom-nav {
  width: 100%;
  height: calc(var(--frame-width) - 2px);
}


#left-nav,
#right-nav {
  width: calc(var(--frame-width) - 2px);
  height: calc(100% + 2px);
  top: -1px;
}


#top-nav {
  top: 1px;
}


#bottom-nav {
  bottom: 0px;
}


#left-nav {
  left: 1px;
}


#right-nav {
  right: 1px;
}


#left-nav a>span {
  transform: rotate(-90deg);
}


#left-nav button>span {
  transform: rotate(-90deg);
}


#right-nav a>span {
  transform: rotate(90deg);
}


#right-nav button>span {
  transform: rotate(90deg);
}

.frame {
  height: var(--vh-100);
  max-height: var(--vh-100);
  // max-height: -webkit-fill-available;
  transform: translateX(0);
}

.frame::before {
  display: none;
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  height: calc(100% - var(--frame-width) * 2 + 0px);
  top: calc(var(--frame-width) - 0px);
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.frame::after {
  display: none;
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  width: calc(100% - var(--frame-width) * 2 + 0px);
  left: calc(var(--frame-width) - 0px);
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  pointer-events: none;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--frame-with-padding) var(--frame-width) 110px;
  height: var(--vh-100);
  box-sizing: border-box;
  transition: all 0.1s;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    padding-bottom: var(--frame-with-padding);
  }
}

main.spaced {
  justify-content: space-between;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.logo {
  width: 120px;
  display: block;
  transition: all .2s ease-in-out;

  img {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 82px;
  }
}

.main-text-lg {
  font-size: clamp(32px, 5.5vw, 64px);
  line-height: clamp(32px, 5.5vw, 64px);
  font-weight: 600;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
  font-family: var(--primary-font);

  gap: 0 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    gap: 0 16px;
  }
}

.main-text-french,
.main-text-spanish{
  font-size: clamp(32px, 5.5vw, 48px);
  line-height: 100%;

  @media (max-width: 768px) {
    font-size: clamp(24px, 5.5vw, 32px);
  }
}

.main-text-off{

  @media(max-width:768px){
    display:none;
  }
}

.main-text-chinese{
  display:none;
  @media(max-width:768px){
    display:inline-block;
  }
}

.text-mobile{
  display:none;

  @media(max-width:768px){
    display:inline-block;
  }
}

.swiper-wrapper,
.swiper-slide {
  height: var(--vh-100);
  max-width: 100vw;
}

.moving #outer-frame {
  display: none;
}

.moving .frame::before,
.moving .frame::after {
  display: block;
}

.movingH #swiperV>.swiper-wrapper>div:not(.swiper-slide-active)>.frame::before,
.movingH #swiperV>.swiper-wrapper>div:not(.swiper-slide-active)>.frame::after {
  display: none;
}

.movingV #swiperH>.swiper-wrapper>div:not(.swiper-slide-active)>.frame::before,
.movingV #swiperH>.swiper-wrapper>div:not(.swiper-slide-active)>.frame::after {
  display: none;
}

.moving .edge-nav {
  display: none;
}

/* Small devices */
@media only screen and (max-width: 768px) {
  :root {
    --frame-width: 14px;
    --frame-with-padding: calc(var(--frame-width) + 20px);
  }

  .edge-nav {
    display: none;
  }
}

// COMMON ELEMENTS FOR SCREENS
.screen-learn-more {
  display: block;
  width: fit-content;
  margin: 1.5rem auto 0;
  color: #808080;

  span {
    display: block;
  }

  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
}

#cursor {
  position: fixed;
  background-color: #000000;
  pointer-events: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transition: width 0.2s linear, height 0.2s linear, opacity 0.2s linear;
  z-index: var(--cursor-z-index);

  @media (max-width: 1023px) {
    display: none;
  }
}

body.dark-theme {
  #cursor {
    background-color: white;
  }

  .swiper-slide-active .logo {
    opacity: 0;
  }
}

body.lock-menu {

  .edge-nav,
  a {
    pointer-events: none;
  }
}

// SPLASH SCREEN

#splash-screen {
  position: fixed;
  left: calc(var(--frame-width) + 1px) !important;
  right: calc(var(--frame-width) + 1px) !important;
  top: calc(var(--frame-width) + 1px) !important;
  bottom: var(--frame-width) !important;
  z-index: 9;
  background-color: var(--white);
  transition: all .4s ease-in, opacity .3s cubic-bezier(.637, .013, .602, .989);
  opacity: 1;

  // display: flex;
  // align-items: center;
  // justify-content: center;
  img {
    position: absolute;
    top: 50%;
    transform: translateX(0%) translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: width .5s ease-in, top .5s ease-in;
    width: 398px;

    @media (max-width: 768px) {
      width: 137px !important;
    }
  }

  &.hide {
    img {
      top: 61px !important;
      width: 120px !important;

      @media (max-width: 768px) {
        top: 34px !important;
        width: 82px !important;
      }
    }
  }

  &.close {
    opacity: 0;
    z-index: -1 !important;
  }
}


// HOW SCREEN

.marques {
  width: 100%;

  p {

    @media(max-width:700px){
      margin:24px 15px 0px 15px; 
      
    }
    
  }

  .screen-learn-more{
    @media(max-width:700px){
      margin-left: auto;
      margin-right: auto;
      
    }
  }

  .marquee {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;

    &:first-of-type {
      margin-bottom: 1.5rem;

      @media (max-width: 768px) {
        margin-bottom: .5rem;
      }
    }

    button {
      margin-right: 140px;
      color: var(--black);

      @media (max-width: 768px) {
        margin-right: 32px;
      }
    }

    .marqueeContent {
      display: flex;
      animation: none;
      
      
    }
  }
}


// WHERE SCREEN

@keyframes showHide {
  100% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  30% {
    opacity: 0.5;
  }

  0% {
    opacity: 1;
  }
}

img.where-image {
  max-width: 112px;
  position: absolute;

  @media (max-width: 768px) {
    max-width: 82px;
  }

  opacity: 0;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: showHide;
  transition: all .2s ease-in-out;

  &:nth-child(1) {
    top: 15%;
    left: 22%;
    animation-duration: 6s;
    animation-delay: 3s;

    @media (max-width: 768px) {
      top: auto;
      bottom: 11%;
      left: 52%;
    }
  }

  &:nth-child(2) {
    top: 20%;
    right: 10%;
    animation-duration: 5s;
    animation-delay: 6s;

    @media (max-width: 768px) {
      top: 54%;
      right: 10%;
    }
  }

  &:nth-child(3) {
    bottom: 16%;
    left: 10%;
    animation-duration: 5s;
    animation-delay: 9s;

    @media (max-width: 768px) {
      bottom: auto;
      left: 72%;
      top: 14%;
    }
  }

  &:nth-child(4) {
    top: 16%;
    right: 30%;
    animation-duration: 12s;
    animation-delay: 12s;

    @media (max-width: 768px) {
      left: 10%;
    }
  }

  &:nth-child(5) {
    bottom: 14%;
    right: 32%;
    animation-duration: 8s;
    animation-delay: 8s;

    @media (max-width: 768px) {
      bottom: 8%;
      right: auto;
      left: 10%;
    }
  }
}

.where-screen {
  position: relative;
  z-index: 2;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    gap: 0 3.75rem;
    justify-content: center;

    @media (max-width: 1250px) {
      max-width: 980px;
    }

    @media (max-width: 1110px) {
      max-width: 880px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }

    li {
      margin-bottom: 1rem;

      @media (max-width: 1023px) {
        margin-bottom: .5rem;
      }
      
      .btn-li-font{
        font-size: clamp(36px, 5.5vw, 48px);

        @media(max-width:700px){
          font-size:min(24px);
        }
      }

      button.btn {
        transition: all .4s ease-in-out;
        color: var(--black);

        &:hover {
          opacity: 1;
        }
      }
    }

    &:hover {
      li {
        button {
          opacity: .5;
        }
      }
    }
  }
}

// REACH OUT SCREEN
.reach-out-screen {
  padding: 0 16px;
  p {
    max-width: 740px;
    margin: 24px auto 0;
    line-height: 1.4;
    font-weight: 400;
    color: #808080;

    a {
      text-decoration: underline;
      color: #808080;
    }
  }
}

.reach-out-chinese{
  p{
    @media(max-width:700px){
      margin:10px auto 0;
    }
  }
}

// MAIN MENU SCREEN
#main-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--black);
  z-index: var(--menu-z-index);
  color: var(--white);

  transition: all 350ms ease-in;
  // transform: translate(0px, 100vh);
  visibility: hidden;
  opacity: 0;

  .spaced {
    padding: var(--frame-with-padding) 0 110px;

    @media (max-width: 768px) {
      padding: var(--frame-with-padding) 0 62px;
    }
  }

  * {
    color: var(--white);
  }

  .menu-wrapper {
    padding: 0 32px;
  }

  .language-selector {
    transition: all .4s ease-in-out;

    @media(max-width: 768px) {
      top: 33px;
    }


    .active-lang-menu:hover span,
    .active-lang-menu.active span{
      color:var(--white);
      opacity: 1;
    }

     span {
      color: #7f7f7f;
    } 

}
  
  

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      button.btn {
        padding-bottom: .5rem;
        transition: all .2s ease-in-out;

        @media (max-width: 768px) {
          padding: 0;
        }

        &:hover {
          opacity: 1;
        }

      }
    }

    &:hover {
      li {
        button {
          opacity: .5;
        }
      }
    }
  }

  .line-bottom {
    bottom: var(--frame-width);
    height: 1px;

    @media (max-width: 768px) {
      bottom: 48px;
    }
  }

  .internal-links {
    position: absolute;
    bottom: 23px;
    left: 0;
    right: 0;
    text-align: center;

    button {
      color: #808080;

      &:first-of-type {
        margin-right: 2.5rem;

        @media (max-width: 768px) {
          margin-right: 1.5rem;
        }
      }
    }

    @media (max-width: 768px) {
      bottom: 16px;
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

// WHERE SCREENS

.detail-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--black);
  z-index: var(--where-z-index);
  color: var(--white);

  transition: all 250ms ease-in;
  transform: translate(0px, 100vh);
  visibility: hidden;

  .spaced {
    padding: var(--frame-width);
    overflow-x: auto;
    // max-width: 90rem;
    margin: 0 auto;
  }

  .detail-screen-closer {
    --frame-closer: calc(var(--frame-width) + 16px);
    position: fixed;
    top: 24px;
    left: 0;
    right: 0;
    z-index: var(--where-closer-z-index);
    text-align: center;
    width: calc(100vw - var(--frame-closer));
    margin: 0 auto;

    @media (max-width: 768px) {
      height: 40px;
      top: calc(var(--frame-width) + 1px);
      background-color: var(--black);
    }
  }

  * {
    color: var(--white);
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  .where-screen-header {
    // padding: 12.5rem 0;
    margin-top: 20vh;
    margin-bottom: 15vh;

    @media (max-width: 768px) {
      // padding: 10rem 0;
    }

    h1,
    h6 {
      margin-bottom: 1.5rem;

      @media (max-width: 768px) {
        margin-bottom: 16px;
      }
    }
  }

  .sticky {
    position: sticky;
    top: 1.5rem;
    @media (max-width: 768px) {
      position: relative;
    }

    img {
      // position: sticky;
      // top: 50%;
      
      // transform: translateX(0%) translateY(-100%);
    }
    &.active {
      height: calc(var(--vh-100) - 130px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .where-screen-content {
    display: flex;
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;

    &:last-of-type {
      margin-bottom: 4rem;

      @media (max-width: 768px) {
        margin-bottom: 2rem;
      }
    }

    &:first-of-type {
      @media (max-width: 768px) {
        padding-bottom: 0;
      }
    }

    &.reverse {
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .where-screen-left,
    .where-screen-right {
      width: 100%;

      @media (min-width: 768px) {
        width: 50%;
      }
    }

    .where-screen-content-wrapper {
      padding: 0 1.5rem 0;
      max-width: 28.25rem;
      margin: 0 auto;
      text-align: left;

      &.full-height {
        height: 100%;
      }

      &.uk.full-height {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
      }

      .hide-on-scroll {
        opacity: 1;
      }

      .title {
        @media (max-width: 768px) {
          margin-top: 3rem;
        }

        &.mobile {
          margin-bottom: 3rem;
        }
      }

      .where-screen-content-texts {
        margin-top: 6rem;
        border-top: 1px solid var(--white);
        padding-top: 1.5rem;

        @media (max-width: 768px) {
          margin-top: 3rem;
        }

        .where-link {
          display: block;
          width: fit-content;
          padding: 40px 0 0;
          color: var(--white);
          &:hover,
          &:focus {
            color: var(--white);
          }
        }

        &.no-desktop-title {
          margin-top: 0 !important;
        }

        h5 {
          margin: 0 0 1.5rem;
        }

        p {
          margin-top: 0;
          margin-bottom: 1.5rem;

          a {
            text-decoration: underline;
            color: var(--white);
          }

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }

    .where-screen-right {
      .where-screen-content-wrapper {
        img {
          width: 323px;
          margin: 48px auto 0;
        }
      }
    }
  }

  &.active {
    transform: translate(0, 0);
    visibility: visible;
    opacity: 1;
  }
}

.stats {
  margin-top: 9rem;

  @media (max-width: 768px) {
    margin-top: 3rem;
  }

  &.no-margin {
    margin: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin-bottom: 2.5rem;
      text-align: left;
      opacity: 0;
      transition: all .5s cubic-bezier(.637, .013, .602, .989);


      &.active {
        opacity: 1;
      }

      h6 {
        margin-bottom: .5rem;

        @media (max-width: 768px) {
          margin-bottom: .25rem;
        }
      }

      p {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid var(--white);

        @media (max-width: 768px) {
          padding-bottom: 1rem;
        }
      }

      &:last-of-type {
        margin-bottom: 0;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.detail-screen.legal-screen {
  background: #E6E6E6;

  * {
    color: var(--black);
  }

  .line-bottom,
  .line-right,
  .line-left,
  .line-top {
    border-color: var(--black);
  }

  .line-top,
  .line-bottom {
    background: #E6E6E6;
  }

  .detail-screen-closer {
    background-color: #E6E6E6;
  }

  .privacy-screen-content {
    padding: 0 1.5rem 0;
    max-width: 650px;
    margin: 0 auto;
    text-align: left;

    .french-title{
      @media (max-width:768px){
        font-size:24px;
        word-break: keep-all;
      }
    }
    

    @media (max-width: 768px) {
      padding: 0 1rem 0;
    }

    h3 {
      margin: 96px 0 128px;
      text-align: center;
      
      @media (max-width: 768px) {
        margin: 80px 0 72px;
        word-break: break-all;
      }
    }

    p {
      margin: 0 0 24px;
    }

    a {
      text-decoration: underline;
    }

    .no-margin-top {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }

    .no-margin {
      margin: 0;

      &:first-of-type {
        padding-top: 0;
      }
    }

    h6 {
      margin-bottom: 4px;
    }

    .privacy-margin-bottom{
      margin-bottom:24px;

      
    }

    h5 {
      padding: 24px 0 24px;
    }

    .title-space {
      padding: 32px 0;
    }

    ul {
      margin: 0;
      padding: 0 0px 24px 24px;
    }

    .with-padding {
      li {
        margin-bottom: 32px;

        &:last-of-type {
          margin: 0;
        }
      }
    }

    .addresses {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 12px;
        padding-bottom: 12px;
        padding-top: 12px;
        border-top: 1px solid var(--black);

        .h6 {
          margin-bottom: 16px;
        }

        .heading-7 {
          margin: 0;
        }

        p {
          margin: 0;
          ;
        }
      }
    }

    .space-x2 {
      margin-bottom: 32px;

      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
  }
}

.accordion-container.custom-accordion {
  .ac {
    border: 0;
    border-top: 1px solid var(--black);
    padding: 0;
    background-color: transparent;
    margin: 0;
    margin-bottom: 16px;

    .ac-trigger {
      padding: 12px 0;
      font: initial;
      font-family: var(--primary-font);
      font-weight: 600;
      font-size: 14px;
      line-height: 1.1;
      cursor: none;

      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        margin: 0;
        transform: rotate(180deg);
        transition: all .2s ease-in-out;
      }

      &:after {
        display: none;
      }
    }

    .ac-panel {

      >p,
      h5,
      h6 {
        &:first-of-type {
          padding-top: 16px;
        }
      }
    }

    &.is-active {
      &>.ac-header .ac-trigger {
        img {
          transform: rotate(0);
        }
      }
    }

  }
}

.how-detail-screen {
  .how-section {
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 200vh;
    height: 200vh;
    padding: 30vh 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .how-section-content {
    padding: 0 24px;
    max-width: 988px;
    margin: 0 auto;
    position: sticky;
    top: 0;

    top: 50%;
    left: 50%;
    transform: translateX(0%) translateY(-50%);

    @media (max-width: 768px) {
      padding: 0 16px;
      max-width: 100%;
    }

    h4 {
      span {
        opacity: 1 !important;
      }
    }

    h6 {
      margin-bottom: 24px;

      @media (max-width: 768px) {
        margin-bottom: 16px;
      }
    }

    h5 {
      margin-top: 32px;
      color: #808080;
    }
  }
}

.who-wrapper-spanish{
  max-width: 1124px;
  margin: 0 auto;
  padding: 0 24px;
  @media (max-width: 768px) {
    padding: 0 16px;

    h5{
      font-size: min(16px);
    }

    h4{
      font-size:min(16px);
    }
  }
}

.who-wrapper {
  max-width: 1124px;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: 768px) {
    padding: 0 16px;

    h5{
      font-size: min(16px);
    }

    h4{
      font-size:min(20px);
    }
  }
  button.btn {
    color: var(--black);
  }
}

#landscape {
  position: fixed;
  background: white;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  display: none;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.marquee.noAnimation .marqueeContent {
  animation: none !important;
}

.mission-screen {

  p {

  @media(max-width:500px){
    margin:24px 15px 0px 15px; 
  }

  }
  .screen-learn-more {
    display: none;

    @media (max-width: 768px) {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

#onetrust-consent-sdk {
  * {
    cursor: auto !important;
  }
  h1, h4, h3, h4, h5, h6 {
    text-transform: uppercase !important;
    font-family: var(--primary-font) !important;
  }
  button {
    font-size: 14px !important;
    line-height: 1.1 !important;
    text-transform: uppercase !important;
    font-family: var(--primary-font) !important;
    font-weight: 600 !important;
  }
  #onetrust-pc-sdk .category-host-list-handler {
    font-family: var(--secondary-font) !important;
    text-transform: none !important;
    font-weight: normal !important;
  }
}

.swiper-slide-active {
  > .frame > .spaced > .marques {
    .marquee {
      .marqueeContent {
        animation: marquee var(--marquee-duration) linear 0s infinite;
        animation-play-state: var(--marquee-play);
        animation-delay: var(--marquee-delay);
        animation-direction: var(--marquee-direction);

        button {
          display: flex;
          gap: 0 8px;
          transition: all .2s ease-in-out;
        }
      }
    }
    &:hover .marqueeContent {
      animation-play-state: paused;
      button {
        opacity: .5;
      }
    }
  }
}

.light-theme.detail-screen {
  background-color: var(--white);
  * {
    color: var(--black);
  }
  .line-bottom,
  .line-top {
    background-color: var(--white);
  }
  .line-left,
  .line-right,
  .line-bottom,
  .line-top {
    border-color: var(--black);
  }
  .error-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 42px;
    height: var(--vh-100);
    @media (max-width: 768px) {
      padding: 16px;
    }
    h1 {
      font-size: 250px;
      @media (max-width: 768px) {
        font-size: 86px;
      }
    }
  }
}


.evolucionamos{
  
  @media(max-width:760px){
    font-size:1.5rem;
  }
}

.li-spacing{

  @media(max-width:600px){
    margin-top:16px;
  }
}